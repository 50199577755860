import React, { useState } from "react";
import Container from "./Container";
import Logo from "./Logo";
import classes from "./Navbar.module.css";
import clsx from "clsx";

const telObj = {
    formated: "+7 (778) 794-21-06",
    plain: "+77787942106"
}

const socialsDefault = [
    {
        href: "https://www.instagram.com/develkz/",
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor"><path d="M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z" /></svg>
    }
]

const Navbar = ({ children, links = [], logo = <Logo size={32} />, tel = telObj, socials = socialsDefault }) => {
    const [menu, setMenu] = useState(false)
    return (
        <nav className={clsx(classes.root, {[classes.active]: menu})}>
            {children}
            <div className={classes.contact}>
            <Container className={classes.contactContent}>
                <a className={classes.tel} href={`tel:${tel.plain}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                    <span>{tel.formated}</span>
                </a>
                <div className={classes.socials}>
                    {socials.map((el, i) => (
                        <a key={`nav-social-${i}`} href={el.href}>{el.icon}</a>
                    ))}
                </div>
            </Container>
            </div>
            <Container className={classes.content}>
                <div className={classes.logo}>
                    <a href="/">
                        {logo}
                    </a>
                </div>
                <div className={classes.links}>
                    {links.map((el, i) => (
                        <a onClick={()=>setMenu(false)} key={`nav-link-${i}`} href={el.href}>{el.label}</a>
                    ))}
                </div>
                <div onClick={()=>setMenu(!menu)} className={classes.burger}>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </Container>
        </nav>
    )
}

export default Navbar;