import React from 'react';
import Typist from "react-typist";
import TypistLoop from "react-typist-loop";
import Particles from 'react-particles-js';
import Fade from "react-reveal/Fade";

import Navbar from './Components/Navbar';
import Container from "./Components/Container";

import Map from "./Map";

import logoColor from "./Assets/logoColor.svg";
import photo from "./Assets/photo.jpg";

import Atameken from "./Assets/Logos/Atameken.svg";
import ERG from "./Assets/Logos/ERG.svg";
import Karine from "./Assets/Logos/Karine.svg";
import KGM from "./Assets/Logos/KGM.svg";
import KMG from "./Assets/Logos/KMG.svg";
import NurOtan from "./Assets/Logos/nurotan.svg"
import BigLogo from "./Assets/BigLogo.svg";

import "./App.css";

const titleItems = ["Веб-платформы", "Приложения", "Системы", "Сайты"];

const logos = [
  {
    img: Atameken,
    title: "Atameken"
  },
  {
    img: ERG,
    title: "ERG"
  },
  {
    img: KMG,
    title: "KazMunaiGaz"
  },
  {
    img: Karine,
    title: "Karine"
  },
  {
    img: KGM,
    title: "KazGerMunai"
  },
  {
    img: NurOtan,
    title: "NurOtan"
  }
];

const App = () => {
  return (
    <>
      <Navbar links={[
        {
          label: "О нас",
          href: "#about"
        },
        {
          label: "Партнеры",
          href: "#partners"
        },
        {
          label: "Миссия",
          href: "#mission"
        },
        {
          label: "Контакты",
          href: "#contacts"
        }
      ]}>
        <a href="https://upskill.devel.kz" className="banner">
          <Container>
            <span>Образовательный проект UpSkill</span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
          </Container>
        </a>
      </Navbar>
      <div className="hero">
        <Container>
          <Fade bottom cascade>
            <div className="text">
              <img width={48} src={logoColor} alt="Devel.kz logo" />
              <h2>Мы создаем</h2>
              <h1>
                <TypistLoop interval={1000}>
                  {titleItems.map(text => (
                    <Typist key={text} cursor={{ blink: true }}>
                      <span>{text}</span>
                      <Typist.Delay ms={1000} />
                      <Typist.Backspace count={text.length} delay={800} />
                    </Typist>
                  ))}
                </TypistLoop>
              </h1>
              <p>Мы ведем свою деятельность на рынке IT-услуг Казахстана начиная с 2015 года.</p>
            </div>
          </Fade>
        </Container>
        <Particles className="test" canvasClassName="particles" params={{
          "particles": {
            "color": {
              "value": "#D63E2B"
            },
            "number": {
              "value": window.innerWidth > 768 ? 50 : 25
            },
            "size": {
              "value": 3
            },
            "line_linked": {
              "color": "#D63E2B"
            }
          },
          "interactivity": {
            "events": {
              "onhover": {
                "enable": true,
                "mode": "repulse"
              }
            }
          }
        }} height="100%" width="100%" />
      </div>
      <div className="about">
        <div id="about" className="anchor" />
        <Container>
          <div className="photo">
            <Fade distance="25%" left>
              <img src={photo} alt="О нас" />
            </Fade>
          </div>
          <div className="text">
            <Fade top>
              <h2>О нас</h2>
            </Fade>
            <Fade bottom>
              <p>Мы IT-компания, которая специализируется на разработке и внедрении информационных систем, приложений и сайтов.</p>
              <p>А также мы разрабатываем средства автоматизации и предоставления управленческой отчётности.</p>
            </Fade>
          </div>
        </Container>
      </div>
      <div className="partners">
        <div id="partners" className="anchor" />
        <Container>
          <Fade top>
            <h2>Нам доверяют</h2>
          </Fade>
          <Fade bottom cascade>
            <div className="logos">
              {logos.map((el, i) => (
                <img key={`key-logo-{${i}}`} src={el.img} alt={el.title} />
              ))}
            </div>
          </Fade>
        </Container>
      </div>
      <div className="mission">
        <div id="mission" className="anchor" />
        <Container>
          <Fade top>
            <h2>Наша миссия</h2>
          </Fade>
          <div className="two">
            <Fade bottom cascade>
              <div className="two-text">
                <h1>Цифровизиция основных аспектов человеческой жизнедеятельности.</h1>
                <p>Обеспечение удобного и своевременного доступа к любой информации на планете с помощью универсальных информационных систем.</p>
                <p>Создание интеллектуальных решений для оптимизации и автоматизации бизнес процессов.</p>
              </div>
            </Fade>
            <div className="two-logo">
              <Fade><img src={BigLogo} alt="Development Technologies" /></Fade>
            </div>
          </div>
        </Container>
      </div>
      <Map/>
    </>
  )
}

export default App;
