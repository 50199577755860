import React from "react";
import Fade from "react-reveal/Fade";
import Container from "./Components/Container";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import "./Maps.css";

class Maps extends React.Component {
    constructor() {
        super();
        this.state = {
            mobile: window.innerWidth < 768
        };
    }
    componentDidMount() {
        window.addEventListener("resize", () =>
            this.setState({
                mobile: window.innerWidth < 768
            })
        );
    }
    render() {
        return (
            <div className="Maps">
                <div id="contacts" className="anchor" />
                <YMaps
                    query={{
                        ns: "use-load-option",
                        load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon"
                    }}
                >
                    <Map
                        defaultState={{
                            center: [51.099901, 71.426189],
                            zoom: 16,
                            controls: ["zoomControl", "fullscreenControl"]
                        }}
                        instanceRef={ref => {
                            ref && ref.behaviors.disable("scrollZoom");
                        }}
                        width={"100%"}
                        height={this.state.mobile ? 320 : 600}
                    >
                        <Placemark
                            defaultGeometry={[51.099901, 71.426189]}
                            properties={{
                                balloonContentBody: "Devel.kz, ул. Бухар жырау, 30 г. Нур-Султан"
                            }}
                        />
                    </Map>
                </YMaps>
                <Container className="container">
                    <Fade distance="25%" bottom={!this.state.mobile}>
                        <div className="plate">
                            <h2>Наш адрес</h2>
                            <p>ул. Бухар жырау, 30 г. Нур-Султан</p>
                            <a href="mailto:devel.kz@gmail.com">devel.kz@gmail.com</a>
                            <a href="tel:+77787942106">+7 (778) 794 21 06</a>
                        </div>
                    </Fade>
                </Container>
            </div>
        );
    }
}

export default Maps;
